import { useEffect, useRef } from 'react';

const INACTIVITY_LIMIT = 2 * 60 * 1000; // ms

export function useAutoLogout(onLogout) {
    const timerRef = useRef(null);

    const resetTimer = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            onLogout();
        }, INACTIVITY_LIMIT);
    };

    const broadcastActivity = () => {
        // Notify other tabs
        localStorage.setItem('lastActivity', Date.now().toString());
        resetTimer();
    };

    useEffect(() => {
        const handleActivity = () => {
            broadcastActivity();
        };

        const events = ['mousemove', 'keydown', 'click', 'scroll'];
        events.forEach(event => window.addEventListener(event, handleActivity));

        const handleStorage = e => {
            if (e.key === 'lastActivity') {
                resetTimer(); // another tab had activity, reset this tab's timer
            }
        };
        window.addEventListener('storage', handleStorage);

        resetTimer();

        return () => {
            events.forEach(event => window.removeEventListener(event, handleActivity));
            window.removeEventListener('storage', handleStorage);
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);
}
